import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import * as styles from '../styles/pages/howitworks.module.css'

import HeaderOffset from "../components/header/headeroffset"

import image_micocrystals from '../images/howitworks/microscopic-crystals.png'
import image_nervoussytem from '../images/howitworks/nervous-system.png'
import image_killinsect from '../images/howitworks/kill-insect.png'
import image_videoholder from '../images/howitworks/videoholder.png'

const image_knowyourpestmosquito = '../images/howitworks/knowyourpests-mosquito.jpg'
const image_knowyourpesthousefly = '../images/howitworks/knowyourpests-housefly.jpg'
const image_knowyourpestcockroach = '../images/howitworks/knowyourpests-cockroach.jpg'
const image_divider = '../images/divider.jpg'


const HowItWorksPage = () => (
  <Layout activePath='howitworks' pageTitle="How It Works">
  	<HeaderOffset />
	<h2 className={"font-size-large font-style-bold text-centered " +styles.maintitle}>
		HOW DOES <span>BOYSEN<span className='registeredicon'>&reg;</span> BUG OFF&trade;</span> WORK
	</h2>
	<div className="text-centered margin-content">
		<div className={styles.videoholder}>
			<img src={image_videoholder} alt="" className="fullblock" />
			<iframe title='How it works' src="https://www.youtube.com/embed/juqUmy4_YjE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
		</div>
	</div>
	<div className={`text-centered margin-content ${styles.bulletholder}`}>
		<img className={styles.bullet} src={image_micocrystals} alt="Microscopic crystals penetrate the insect's legs" />
		<img className={styles.bullet} src={image_nervoussytem} alt="Attacks its nervous system" />
		<img className={styles.bullet} src={image_killinsect} alt="and kills the insect after a while" />
	</div>
	<div className={`text-centered ${styles.reminder}`}>
		Reminder: Keep product out of reach of children
	</div>
	<hr />
	<div id="knowyourpests" className="text-centered margin-content">
		<h2 className="font-size-large margin-title">
			KNOW YOUR PESTS
		</h2>
		<div>
			KILL THESE DISEASE-CAUSING INSECTS WITH <span className="font-style-bold color-text-primary">BOYSEN<span className="registeredicon">&reg;</span> BUG OFF&trade;</span>
		</div>
	</div>
	<StaticImage layout="fullWidth" alt='Mosquito' src={image_knowyourpestmosquito} className={`fullblock `+styles.imgholder}/>
	<StaticImage layout="fullWidth" alt='' src={image_divider} className={`fullblock`}/>
	<StaticImage layout="fullWidth" alt='Housefly' src={image_knowyourpesthousefly} className={`fullblock `+styles.imgholder}/>
	<StaticImage layout="fullWidth" alt='' src={image_divider} className={`fullblock`}/>
	<StaticImage layout="fullWidth" alt='Cockroach' src={image_knowyourpestcockroach} className={`fullblock `+styles.imgholder}/>

  </Layout>
)

export default HowItWorksPage
